import { PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { vendorColor } from "@vendor/utils/constants";

type ButtonColor = "default" | "black" | "white";
const applePayBtnStyle: ButtonColor | undefined = "black";
const googlePayBtnStyle: ButtonColor | undefined = "black";

export const colors = {
  primary: "#030507",
  primaryDark: "#16171a",
  secondary: "#030507",

  error: "#d41516",
  errorLight: "#fed5d6",
  errorDark: "#5e040a",
  success: "#16A66C",
  successLight: "#a2dbc4",
  successDark: "#0d6441",
  text: "#000000",
  textSecondary: "#5b5b5b",
  textDisabled: "#747779",
  background: "#fafafa",
  lighterBackground: "#ffffff",
  activeSelected: "#737373",
  activeDisabled: "#737373",
  activeDisabledBackground: "#737373",
  paper: "#ffffff",
  border: "#d4d4d4",
  borderLight: "#efefef",
  borderMedium: "#e6e6e6",
  borderDark: "#ababab",
  vendorColor,
  utilityGray: "#737373",
  warning: "#fff5eb",
  warningDark: "#f49639",
  warningBorder: "#f0dcc7",
  warningText: "#5b5b5b",
  highlightColor: "#df2520",
  switchTrackColor: "#030507",
  applePayBtnStyle,
  googlePayBtnStyle,
};

export const fonts = {
  titlesFont: "'Beatrice', Helvetica, Arial, sans-serif",
  textFont: "'GT America', Helvetica, Arial, sans-serif",
};

export const vendorTheme = createTheme({
  typography: {
    fontFamily: fonts.textFont,
    body1: {
      fontSize: "1.6rem",
    },
    h1: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.8rem",
    },
    h2: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.5rem",
    },
    h5: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.5rem",
      fontWeight: "600",
    },
    button: {
      fontFamily: fonts.titlesFont,
    },
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    mode: "light" as PaletteMode,
    primary: {
      main: colors.primary,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
      dark: colors.errorDark,
    },
    success: {
      main: colors.success,
      light: colors.successLight,
      dark: colors.successDark,
    },
    text: {
      primary: colors.text,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
    },
    background: {
      default: colors.background,
      paper: colors.paper,
    },
    action: {
      active: colors.primary,
      hover: colors.primaryDark,
      selected: colors.activeSelected,
      disabled: colors.activeDisabled,
      disabledBackground: colors.activeDisabledBackground,
    },
    divider: colors.border,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
          color: colors.text,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "initial",
        },
        regular: {
          minHeight: "initial",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
        sizeLarge: {
          fontSize: "1.4rem",
          padding: "15px 10px 13px",
        },
        sizeMedium: {
          fontSize: "1.2rem",
          lineHeight: "2.1rem",
        },
        sizeSmall: {
          fontSize: "1.1rem",
          minWidth: "35px",
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          borderWidth: "1px",
          color: colors.text,
          fontSize: "1.5rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "1px 1px 6px rgb(0 0 0 / 9%)",
        },
      },
    },
  },
});
